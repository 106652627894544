// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}
p,li,ul,ol,h1,h2{
  padding: 0;
  margin: 0;
}
li{
  list-style: none;
}
.back-home {
  position: absolute;
  z-index: 99;
  right: 30px;
  bottom: 30px;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,UAAU;EACV,SAAS;AACX;AACA;EACE,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,WAAW;EACX,YAAY;AACd","sourcesContent":[".App {\n  text-align: center;\n}\np,li,ul,ol,h1,h2{\n  padding: 0;\n  margin: 0;\n}\nli{\n  list-style: none;\n}\n.back-home {\n  position: absolute;\n  z-index: 99;\n  right: 30px;\n  bottom: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
